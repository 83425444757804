import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';

const SearchableSelect = ({ options, onChange, loading, label = 'Select an Option' }) => {
    const [value, setValue] = useState(undefined);

    useEffect(() => {
        if (!(onChange instanceof Function)) {
            console.error('SearchableSelect intialized incorrectly onChange is not a function');
        }
    }, [onChange]);

    useEffect(() => {
        onChange(value);
    }, [value, onChange]);

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => option.value === value?.value}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
        />
    );
};

export { SearchableSelect };

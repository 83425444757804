import React, { useState, useRef, useEffect } from 'react';
import {
    Grid,
    TextField,
    Select,
    FormControl,
    Button,
    Checkbox,
    FormControlLabel,
    InputLabel,
    MenuItem,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FileUpload } from '../..';
import { makeStyles, lighten, darken } from '@material-ui/core/styles';
import { Editor } from '@tinymce/tinymce-react';

let getInitialFormData = (formFields, useInitialValues = true) => {
    let initialFormData = {};
    formFields.forEach((item) => {
        if (
            item.inputElement instanceof Function ||
            ['textField', 'textEditor', 'select', 'date', 'time', 'datetime-local'].includes(item.inputElement) ||
            !item.inputElement
        ) {
            initialFormData[item.name] = '';
        }

        if (item.inputElement === 'checkbox') {
            initialFormData[item.name] = false;
        }

        if (item.inputElement === 'file') {
            if (item.multiple) {
                initialFormData[item.name] = [];
            } else {
                initialFormData[item.name] = null;
            }
        }

        if (item.inputElement === 'autocomplete') {
            if (item.inputProps && item.inputProps.multiple) {
                initialFormData[item.name] = [];
            } else {
                initialFormData[item.name] = '';
            }
        }

        if (/*useInitialValues &&*/ item.hasOwnProperty('initialValue') && item.initialValue !== undefined) {
            initialFormData[item.name] = item.initialValue;
        }
    });

    return initialFormData;
};

const FormComponent = ({
    formFields,
    onSubmit,
    button,
    inputElementStyle = {},
    formControlStyle = {},
    onFormDataChange,
    compact = false,
    hideSubmit = false,
}) => {
    const useStyles = makeStyles((theme) => ({
        formControl: {
            width: '100%',
            padding: '10px',
            ...formControlStyle,
        },
    }));

    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const classes = useStyles();
    let initialFormData = getInitialFormData(formFields);

    let resetForm = () => {
        setFormData(initialFormData);
    };

    let [formData, setFormData] = useState(initialFormData);
    let [submitting, setSubmitting] = useState(false);
    const formEl = useRef(null);

    useEffect(() => {
        if (onFormDataChange && onFormDataChange instanceof Function) {
            let validity = formEl.current.checkValidity();
            onFormDataChange(formData, validity, resetForm, formEl.current.reportValidity.bind(formEl.current));
        }
    }, [formData]);

    let setFormField = (name, value) => {
        let data = { ...formData };
        data[name] = value;
        // check if any other fields depend on this one, reset
        formFields.forEach((item) => {
            if (item.dependencies && item.dependencies.length) {
                if (item.dependencies.includes(name)) {
                    let initialFormDataWithoutInitialValues = getInitialFormData(formFields, false);
                    data[item.name] = initialFormDataWithoutInitialValues[item.name];
                }
            }
        });
        setFormData(data);
    };

    let getDisplayItem = (item) => {
        if (item.hasOwnProperty('display') === false) {
            return true;
        }

        if (item.hasOwnProperty('display') && item.display === false) {
            return false;
        }

        if (item.display instanceof Function) {
            return item.display(formData);
        }

        return true;
    };

    return (
        <form ref={formEl}>
            <Grid container alignItems="center">
                {formFields.map((item, index) => (
                    <Grid
                        item
                        xs={12}
                        md={item.gridValue || 12}
                        key={`${item.name}-${item.index}`}
                        style={{ display: getDisplayItem(item) ? null : 'none' }}
                    >
                        <FormControl
                            id={item.formControlId}
                            className={classes.formControl}
                            style={{
                                display: getDisplayItem(item) ? null : 'none',
                                padding: compact ? '2px' : undefined,
                                margin: compact ? 0 : undefined,
                            }}
                        >
                            <InputElement
                                compact={compact}
                                item={item}
                                display={getDisplayItem(item)}
                                formData={formData}
                                setFormField={setFormField}
                                initialFormData={initialFormData}
                                inputElementStyle={inputElementStyle}
                            />
                        </FormControl>
                    </Grid>
                ))}
                {!hideSubmit && (
                    <Grid
                        item
                        md={button && button.gridValue ? button.gridValue : 12}
                        align={button && button.align ? button.align : 'inherit'}
                    >
                        <Button
                            id={button && button.text ? button.text.replaceAll(' ', '') : 'Submit'}
                            style={button && button.style ? button.style : { marginTop: '5px' }}
                            type="submit"
                            variant="outlined"
                            disabled={
                                (button &&
                                    button.disabled &&
                                    button.disabled instanceof Function &&
                                    button.disabled(formData) === true) ||
                                submitting
                            }
                            onClick={async (e) => {
                                e.preventDefault();
                                setSubmitting(true);
                                let validity = formEl.current.checkValidity();
                                if (validity === false) {
                                    formEl.current.reportValidity();
                                    setSubmitting(false);
                                    return;
                                }

                                if (onSubmit && onSubmit instanceof Function) {
                                    let submitObj = {};
                                    Object.keys(formData).forEach((key) => {
                                        let formField = formFields.find((item) => item.name === key);
                                        let submitField = true;
                                        if (
                                            !!formField &&
                                            formField.hasOwnProperty('ignoreOnSubmit') &&
                                            !formFields.ignoreOnSubmit
                                        ) {
                                            submitField = false;
                                        }
                                        if (!!formField && submitField) {
                                            // if the item is not displayed, reset to initial value
                                            let itemDisplayed = getDisplayItem(formField);
                                            if (itemDisplayed) {
                                                if (formData[key] && formData[key].value) {
                                                    submitObj[key] = formData[key].value;
                                                } else {
                                                    submitObj[key] = formData[key];
                                                }
                                            } else {
                                                let initialFormDataWithoutInitialValues = getInitialFormData(
                                                    formFields,
                                                    false
                                                );
                                                submitObj[key] = initialFormDataWithoutInitialValues[key];
                                            }
                                        }
                                    });
                                    await onSubmit(submitObj, resetForm);
                                    if (isMounted.current) {
                                        setSubmitting(false);
                                    }
                                } else {
                                    console.log('onSubmit is not defined');
                                }
                            }}
                        >
                            {button && button.text ? button.text : 'Submit'}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </form>
    );
};

const InputElement = ({
    item,
    display,
    formData,
    setFormField,
    compact = false,
    initialFormData,
    inputElementStyle,
}) => {
    const useStyles = makeStyles((theme) => ({
        inputElement: {
            padding: '10px',
            ...inputElementStyle,
        },
        inputElementSelect: {
            padding: compact ? '3px' : '8px',
            margin: '5px',
            ...inputElementStyle,
        },
        selectDisabled: {
            color: 'rgba(0, 0, 0, 0.38)',
        },
    }));

    const classes = useStyles();

    if (display !== true) {
        return null;
    }

    let inputProps = {};
    if (item.inputProps) {
        Object.keys(item.inputProps).forEach((key) => {
            if (
                [
                    'min',
                    'max',
                    'step',
                    'required',
                    'maxLength',
                    'readOnly',
                    'disabled',
                    'style',
                    'disableUnderline',
                    'variant',
                    'requiredDependencies',
                ].includes(key)
            ) {
                inputProps[key] = item.inputProps[key];
            }
        });
    }

    if (item.inputElement instanceof Function) {
        // pass in component as input element
        return (
            <div>
                {item.inputElement({
                    formData,
                    setFormField: (value) => {
                        setFormField(item.name, value);
                    },
                })}
            </div>
        );
    }

    // field is required if different field is set
    if (inputProps.requiredDependencies && inputProps.requiredDependencies.length > 0) {
        inputProps.requiredDependencies.forEach((reqDep) => {
            if (formData[reqDep]) {
                inputProps['required'] = true;
            }
        });
    }

    if (item.inputElement === 'file') {
        if (
            item.initialValue &&
            typeof item.initialValue === 'string' &&
            item.initialValue === formData[item.name] &&
            item.accept.includes('.png')
        ) {
            return (
                <>
                    <div>{item.label}</div>
                    <img style={{ height: 100, width: 100 }} src={item.initialValue} />
                    <Button
                        onClick={() => {
                            setFormField(item.name, null);
                        }}
                    >
                        Clear
                    </Button>
                </>
            );
        } else {
            return (
                <FileUpload
                    maxNumFiles={item.maxNumFiles || null}
                    inputProps={inputProps}
                    multiple={item.multiple}
                    accept={item.accept}
                    file={formData[item.name]}
                    text={item.label}
                    onFileChange={(file) => {
                        setFormField(item.name, file);
                    }}
                />
            );
        }
    }

    if (item.inputElement === 'textField' || !item.inputElement) {
        const validateNumericInput = (value, editProps, eventTarget) => {
            if (value === '-') {
                return true;
            }

            if (isNaN(value)) {
                return false;
            }

            if (editProps.integer) {
                if (value.indexOf('.') > 0 || !Number.isInteger(Number(value))) {
                    return false;
                }
            }

            let validationError = '';
            if (editProps.hasOwnProperty('min') && Number(value) < Number(editProps.min)) {
                validationError = `Value must be greater than or equal to ${editProps.min}.`;
            } else if (editProps.hasOwnProperty('max') && Number(value) > Number(editProps.max)) {
                validationError = `Value must be less than or equal to ${editProps.max}.`;
            }
            eventTarget.setCustomValidity(validationError);

            return true;
        };

        let inputType = 'text';
        if (item.inputProps && item.inputProps.type !== 'number') {
            inputType = item.inputProps.type;
        }

        return (
            <TextField
                id={
                    item.label
                        ? `${item.label.replaceAll(' ', '')}_textField`
                        : `${item.name.replaceAll(' ', '')}_textField`
                }
                required={item.inputProps && item.inputProps.required === true ? true : false}
                multiline={item.inputProps && item.inputProps.multiline === true ? true : false}
                className={classes.inputElement}
                label={item.label}
                inputProps={inputProps}
                type={inputType}
                InputProps={item.InputProps || null}
                InputLabelProps={item.InputLabelProps || null}
                disabled={item.value && !item?.inputProps?.onChange ? true : false}
                value={item.value ? item.value : formData[item.name]}
                disableunderline={item.inputProps && item.inputProps.disableUnderline === true ? 'true' : 'false'}
                onChange={(event) => {
                    // prevent invalid numeric input
                    if (item.inputProps && item.inputProps.type === 'number') {
                        let valid = validateNumericInput(event.target.value, item.inputProps, event.target);
                        if (!valid) {
                            return false;
                        }
                    }
                    if (item.inputProps && item.inputProps.onChange) {
                        item.inputProps.onChange(event, formData);
                    }
                    setFormField(item.name, event.target.value);
                }}
            />
        );
    }

    if (item.inputElement === 'textEditor') {
        return (
            <div>
                <h4 style={{ textAlign: 'left' }}>{item.label}</h4>
                <Editor
                    value={formData[item.name]}
                    onEditorChange={(content) => {
                        setFormField(item.name, content);
                    }}
                    apiKey="xnqkkmkpkha1tyrh74njwesb6yh94b9y3ppwzxrud8rkrsre"
                    init={{
                        height: 200,
                        menubar: false,
                        content_style: 'body { font-size: 14px; }',
                        fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                        ],
                        toolbar:
                            'undo redo | fontsizeselect | formatselect | bold italic strikethrough backcolor link | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist | help',
                    }}
                />
            </div>
        );
    }

    if (item.inputElement === 'checkbox') {
        return (
            <CheckBoxComponent
                inputProps={inputProps}
                item={item}
                classes={classes}
                onChange={(event) => {
                    if (item.hasOwnProperty('value') && item.value !== undefined) {
                        return;
                    }

                    if (item.inputProps && item.inputProps.onChange) {
                        item.inputProps.onChange(event, !formData[item.name]);
                    }
                    setFormField(item.name, !formData[item.name]);
                }}
                formData={formData}
            />
        );
    }

    if (item.inputElement === 'date') {
        let inputLabelProps = {};
        inputLabelProps['shrink'] = true;

        return (
            <TextField
                id={
                    item.label
                        ? `${item.label.replaceAll(' ', '')}_dateField`
                        : `${item.name.replaceAll(' ', '')}_dateField`
                }
                type="date"
                onClick={(event) => {
                    if (event.target && typeof event.target.showPicker === 'function') {
                        event.target.showPicker();
                    }
                }}
                onKeyDown={(event) => {
                    if (item.disableTextInput) {
                        event.preventDefault();
                        return false;
                    }
                }}
                label={item.label}
                value={!!item.value ? item.value : formData[item.name]}
                onChange={(event) => {
                    if (item.inputProps && item.inputProps.onChange) {
                        item.inputProps.onChange(event, event.target.value);
                    }
                    setFormField(item.name, event.target.value);
                }}
                InputLabelProps={inputLabelProps}
                inputProps={inputProps}
                required={item.inputProps && item.inputProps.required === true ? true : false}
            />
        );
    }

    if (item.inputElement === 'time') {
        let inputLabelProps = {};
        inputLabelProps['shrink'] = true;

        return (
            <TextField
                id={
                    item.label
                        ? `${item.label.replaceAll(' ', '')}_textField`
                        : `${item.name.replaceAll(' ', '')}_textField`
                }
                type="time"
                label={item.label}
                value={formData[item.name]}
                onChange={(event) => {
                    setFormField(item.name, event.target.value);
                }}
                InputLabelProps={inputLabelProps}
                inputProps={inputProps}
                required={item.inputProps && item.inputProps.required === true ? true : false}
            />
        );
    }

    if (item.inputElement === 'datetime-local') {
        let inputLabelProps = {};
        inputLabelProps['shrink'] = true;

        return (
            <TextField
                id={
                    item.label
                        ? `${item.label.replaceAll(' ', '')}_textField`
                        : `${item.name.replaceAll(' ', '')}_textField`
                }
                type="datetime-local"
                label={item.label}
                value={formData[item.name]}
                onChange={(event) => {
                    setFormField(item.name, event.target.value);
                }}
                InputLabelProps={inputLabelProps}
                inputProps={inputProps}
                required={item.inputProps && item.inputProps.required === true ? true : false}
            />
        );
    }

    if (item.inputElement === 'autocomplete') {
        let selected = [];
        if (item.inputProps.multiple) {
            selected = formData[item.name].map((item) => JSON.stringify(item.value));
        }
        let options = [];
        if (item.inputProps.opts instanceof Function) {
            options = item.inputProps.opts(formData) || [];
        } else if (Array.isArray(item.inputProps.opts) === true) {
            options = item.inputProps.opts;
        }
        options = options.map((thing, i) => {
            let text, value;
            if (thing.hasOwnProperty('text') && thing.hasOwnProperty('value')) {
                text = thing.text;
                value = thing.value;
            } else {
                text = thing;
                value = thing;
            }
            return { text, value };
        });

        return (
            <>
                <Autocomplete
                    id={
                        item.label
                            ? `${item.label.replaceAll(' ', '')}_autocomplete`
                            : `${item.name.replaceAll(' ', '')}_autocomplete`
                    }
                    multiple={item.inputProps.multiple || false}
                    value={formData[item.name]}
                    onChange={(event, value, reason, detail) => {
                        if (item.inputProps.onChange) {
                            item.inputProps.onChange(event, value, formData, reason, detail);
                        }
                        setFormField(item.name, value);
                    }}
                    options={options.filter((option) => {
                        // for multiple, don't allow selecting the same item twice
                        if (!item.inputProps.multiple) {
                            return true;
                        }

                        if (selected.includes(JSON.stringify(option.value))) {
                            return false;
                        }
                        return true;
                    })}
                    disabled={item.disabled || item.inputProps.disabled}
                    getOptionLabel={(option) => option.text || ''}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={item.label}
                            required={
                                item.inputProps.required &&
                                (!item.inputProps.multiple || formData[item.name].length === 0)
                            }
                        />
                    )}
                />
            </>
        );
    }

    if (item.inputElement === 'select') {
        let options = [];
        if (item.inputProps.opts instanceof Function) {
            options = item.inputProps.opts(formData) || [];
        } else if (Array.isArray(item.inputProps.opts) === true) {
            options = item.inputProps.opts;
        }
        options = options.map((thing, i) => {
            let text, value;
            if (thing.hasOwnProperty('text') && thing.hasOwnProperty('value')) {
                text = thing.text;
                value = thing.value;
            } else {
                text = thing;
                value = thing;
            }
            return { text, value };
        });

        return (
            <>
                {item.label && (
                    <InputLabel
                        required={item.inputProps && item.inputProps.required === true ? true : false}
                        shrink={true}
                    >
                        {item.label}
                    </InputLabel>
                )}
                <Select
                    native
                    id={
                        item.label
                            ? `${item.label.replaceAll(' ', '')}_select`
                            : `${item.name.replaceAll(' ', '')}_select`
                    }
                    className={`${classes.inputElementSelect} ${
                        item.value && !item?.inputProps?.onChange ? classes.selectDisabled : ''
                    }`}
                    label={item.label}
                    inputProps={inputProps}
                    value={item.value || formData[item.name]}
                    disableUnderline={item.inputProps && item.inputProps.disableUnderline === true ? true : false}
                    onChange={(event) => {
                        // See if we have a custom handler attached
                        if (item.inputProps.onChange) {
                            item.inputProps.onChange(event, event.target.value);
                        }
                        setFormField(item.name, event.target.value);
                    }}
                >
                    {options[0] && !!options[0].value && <option disabled value=""></option>}
                    {options.map((item, i) => (
                        <option key={i} value={item.value} disabled={item?.disabled === true}>
                            {item.text}
                        </option>
                    ))}
                </Select>
            </>
        );
    }
};

let CheckBoxComponent = ({ inputProps, item, classes, onChange, formData }) => {
    let [checkValue, setCheckValue] = useState(false);

    useEffect(() => {
        if (item.hasOwnProperty('value') && item.value !== undefined) {
            if (item.value !== checkValue) {
                setCheckValue(item.value);
            }
        } else {
            if (typeof formData[item.name] !== 'undefined') {
                if (formData[item.name] !== checkValue) {
                    setCheckValue(formData[item.name]);
                }
            }
        }
    }, [item, formData]);

    return (
        <FormControlLabel
            control={
                <Checkbox
                    id={`${item.label.replaceAll(' ', '')}_checkbox`}
                    checked={checkValue}
                    onChange={onChange}
                    color="primary"
                    disabled={inputProps.disabled === true ? true : false}
                    inputProps={inputProps}
                    className={classes.inputElement}
                    required={item.inputProps && item.inputProps.required === true ? true : false}
                />
            }
            label={item.label}
        />
    );
};

export { FormComponent };

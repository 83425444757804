import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { DataTable, Notifications } from '../../../../shared';
import { ActionsCell } from './ActionsCell';
import { PalletTabExpansion } from './palletTabExpansion';
import { ActionModal } from './ActionModal';
import { Button, CircularProgress } from '@material-ui/core';
import { WarehouseInventoryV2Api } from '../../../../api/warehouseInventoryV2';
import { MovePalletAction } from './actions/movePalletAction';
import { PrintAllCaseLabelsAction } from './actions/printAllCaseLabelsAction';
import { PrintPalletLabelAction } from './actions/printPalletLabelAction';

const warehouseInventoryV2Api = new WarehouseInventoryV2Api();

const PalletTab = ({ onLinkClicked, queryParams }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [loadingPallets, setLoadingPallets] = useState(false);
    const [rows, setRows] = useState([]);
    const [viewActions, setViewActions] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const { user } = useContext(UserContext);

    //search bar queryParams effect
    useEffect(() => {
        handleSearch();
    }, [queryParams]);

    const handleSearch = async () => {
        //TODO handle the filtering of the row data to match queryPrams
        getPallets();
    };

    //Column Definitions
    const columns = [
        { Header: 'Pallet Id', accessor: 'palletId' },
        { Header: 'Active', accessor: 'active', type: 'checkbox' },
        { Header: 'Unique Skus', accessor: 'uniqueSkus' },
        { Header: 'Num Of Lots', accessor: 'numOfLots' },
        { Header: 'Cases', accessor: 'cases' },
        { Header: 'Loose Units', accessor: 'looseUnits' },
        { Header: 'Total Units', accessor: 'totalUnits' },
        {
            Header: 'Location',
            accessor: 'location',
            Cell: ({ row }) => {
                return (
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => handleLocationLinkClick(row.original.location)}
                    >
                        {row.original.location}
                    </Button>
                );
            },
        },
        {
            accessor: 'actions',
            Header: 'Actions',
            Cell: ({ row }) => (
                <ActionsCell row={row} setSelectedRow={setSelectedRow} setViewActions={setViewActions} />
            ),
        },
    ];

    //API call to GET data
    const getPallets = async () => {
        setLoadingPallets(true);
        const results = await warehouseInventoryV2Api.getPallets(user.facilityID);
        if (results.status) {
            setRows(
                results.data.pallets.map((pallet) => {
                    return {
                        palletId: pallet.palletId,
                        active: pallet.numLots > 0,
                        uniqueSkus: pallet.numSku,
                        numOfLots: pallet.numLots,
                        cases: pallet.numCases,
                        looseUnits: pallet.numLooseUnits,
                        totalUnits: pallet.totalUnits,
                        location: pallet.locationName,
                        inventoryType: pallet.inventoryType,
                        locationType: pallet.locationType,
                    };
                })
            );
        } else {
            setNotification({ text: results.msg, level: 'error' });
        }
        setLoadingPallets(false);
    };

    const handleLocationLinkClick = (location) => {
        onLinkClicked({ tabIndex: 1, searchBy: 'locationName', searchTerm: location });
    };

    const handleOnRowClicked = (row) => {
        setSelectedRow(row.original);
    };

    const handleModalClose = () => {
        setViewActions(false);
    };

    const handleSuccess = () => {
        setNotification({ text: 'Success', level: 'success' });
        handleModalClose();
        getPallets();
    };

    //Action Definitions
    const actions = [
        {
            text: 'Move Pallet',
            value: 'movePallet',
            componentJSX: function () {
                return <MovePalletAction palletId={selectedRow?.palletId} onSuccess={handleSuccess} />;
            },
        },
        {
            text: 'Print Pallet Label',
            value: 'printPalletLabel',
            componentJSX: function () {
                return <PrintPalletLabelAction palletId={selectedRow?.palletId} onSuccess={handleSuccess} />;
            },
        },
        {
            text: 'Create Empty Pallet',
            value: 'createEmptyPallet',
            componentJSX: function () {
                return <span>TODO PUT ACTION FORMS HERE for Create Empty Pallet</span>;
            },
        },
        {
            text: 'Pallet Empty',
            value: 'palletEmpty',
            componentJSX: function () {
                return <span>TODO PUT ACTION FORMS HERE for Pallet Empty</span>;
            },
        },
        {
            text: 'Print All Case Labels',
            value: 'printAllCaseLabels',
            componentJSX: function () {
                return (
                    <PrintAllCaseLabelsAction palletId={selectedRow?.palletId} onSuccess={handleSuccess}/>
                );
            },
        },
    ];

    return (
        <>
            <Notifications options={notification} />
            {loadingPallets && <CircularProgress color="inherit" size={48} />}
            {!loadingPallets && (
                <>
                    <DataTable
                        columns={columns}
                        data={rows}
                        editable={false}
                        tableContainerMaxHeight={800}
                        stickyHeader={true}
                        expandable={true}
                        ExpansionComponent={
                            <PalletTabExpansion
                                palletId={selectedRow?.palletId}
                                onSuccess={handleSuccess}
                                locationType={selectedRow?.locationType}
                                onLinkClicked={onLinkClicked}
                                locInvType={selectedRow?.inventoryType}
                            />
                        }
                        onRowClicked={handleOnRowClicked}
                        toolbarEnabled={false}
                    />
                    <ActionModal
                        title={`Actions for Pallet ${selectedRow.palletId}`}
                        open={viewActions}
                        row={selectedRow}
                        actions={actions}
                        closeModal={handleModalClose}
                    />
                </>
            )}
        </>
    );
};

export { PalletTab };

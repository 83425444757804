import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import { FormComponent, Notifications } from '../../../../../shared';
import { warehouseApi } from '../../../../../api/warehouse';
import { Typography } from '@material-ui/core';
import { UserContext } from '../../../../../contexts/UserContext';
import { SearchableSelect } from '../../../../../shared/components/SearchableSelect';
import { WarehouseInventoryV2Api } from '../../../../../api/warehouseInventoryV2';

const warehouseApiV2 = new WarehouseInventoryV2Api();

const AdjustCasesAndLooseUnitsAction = ({ locationId = null, palletId = null, lotId, caseNum, looseUnitNum, onSuccess, onCancel, locInvType }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [currentFormData, setCurrentFormData] = useState({caseNum: caseNum, looseUnitNum: looseUnitNum});
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [fromPallets, setFromPallets] = useState([]);
    const [fromPalletsLoading, setFromPalletsLoading] = useState(false);
    const [showFromPalletField, setShowFromPalletField] = useState(false);
    const [selectedFromPallet, setSelectedFromPallet] = useState();
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (!(onSuccess instanceof Function)) {
            console.error('AdjustCasesAndLooseUnitsAction intialized incorrectly onSuccess is not a function');
        }
        if (!(onCancel instanceof Function)) {
            console.error('AdjustCasesAndLooseUnitsAction initialized incorrectly onCancel is not a function');
        }
    }, [onSuccess, onCancel]);

    const fromPalletOptions = useMemo(() => 
        fromPallets.map((pallet) => ({ label: pallet.palletId.toString(), value: pallet.palletId })), 
        [fromPallets]
    );

    useEffect(() => {
        setShowFromPalletField(locInvType === 'Pallet' && palletId == null);
    }, []);

    useEffect(() => {
        if (showFromPalletField) {
            queryFromPallets();
        }
    }, [showFromPalletField]);

    const queryFromPallets = async () => {
        setFromPalletsLoading(true);

        const result= await warehouseApiV2.getPalletsByLocationIdandLotId(locationId, lotId, user.facilityID);
        if (result?.status) {
            setFromPallets(result?.data?.pallets ?? []);
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }

        setFromPalletsLoading(false);
    };

    const formFields = [
        {
            name: 'numCases',
            label: 'Cases',
            initialValue: currentFormData.caseNum,
            gridValue: 3,
            inputProps: { required: true, type: 'number', integer: true},
        },
        {
            name: 'numUnits',
            label: 'Loose Units',
            initialValue: currentFormData.looseUnitNum,
            gridValue: 3,
            inputProps: { required: true, type: 'number', integer: true },
        },
    ];

    const handleSubmit = async (formData, resetForm) => {
        if (formData.numCases == 0 && formData.numUnits == 0) {
            setShowError(true);
            setErrorMessage('One of the fields must be greater than 0.');
            return;
        }
        setShowError(false);
        setErrorMessage('');
        

        const requestParams = {
            lotID: lotId,
            numCases: formData.numCases,
            numUnits: formData.numUnits,
        };
        if (locationId) {
            requestParams.originLocationID = locationId;
        }
        if (palletId) {
            requestParams.originPalletID = palletId;
        } else if (selectedFromPallet) {
            requestParams.originPalletID = selectedFromPallet;
        }
        const result = await warehouseApi.adjustCaseAndUnitNums(requestParams);
        if (result.status) {
            onSuccess();
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
    };

    const handleFormDataChange = (formData) => {
        setCurrentFormData({caseNum: formData.caseNum, looseUnitNum: formData.numUnits});
    };

    const handleFromPalletChange = useCallback((newValue) => {
        setSelectedFromPallet(newValue?.value);
    }, []);

    return (
        <>
            <Notifications options={notification} />
            <h3>Adjust cases and loose units</h3>
            {showError && (
                <Typography style={{ color: 'red', marginBottom: 10 }}>
                    {errorMessage}
                </Typography>
            )}
            {showFromPalletField && (
                <SearchableSelect
                    onChange={handleFromPalletChange}
                    options={fromPalletOptions}
                    loading={fromPalletsLoading}
                    label="Adjust From Pallet"
                />
            )}
            <FormComponent
                formFields={formFields}
                onSubmit={handleSubmit}
                button={{ text: 'Submit', gridValue: 1 }}
                onFormDataChange={handleFormDataChange}
            />
        </>
    );
};

export { AdjustCasesAndLooseUnitsAction };

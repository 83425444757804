import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { customerApi as api } from '../../../../api/customer';

const CancelModal = ({ rowInfo, setOpen, open, setNotification, getSubscription, getOrders }) => {
    let [loading, setLoading] = useState(false);

    let cancelOrderAuth = async () => {
        setLoading(true);
        let res = await api.cancelOrderAuth(rowInfo?.charge.chargeID, { reason: 'CUSTOMER_REQUESTED_CANCEL' });
        if (!res.status) {
            setNotification({ text: 'Unable to cancel auth: ' + res.msg, level: 'error' });
            setLoading(false);
            return;
        }

        setNotification({ text: 'Order payment authorization cancelled', level: 'success' });
        setOpen(false);
        getSubscription();
        getOrders();
        setLoading(false);
    };

    let cancelOrderLineItem = async () => {
        setLoading(true);
        let res = await api.cancelOrderLineItem(rowInfo?.charge.chargeID, { reason: 'CUSTOMER_REQUESTED_CANCEL' });
        if (!res.status) {
            setNotification({ text: 'Unable to cancel order: ' + res.msg, level: 'error' });
            setLoading(false);
            return;
        }

        setNotification({ text: 'Order cancelled and refunded', level: 'success' });
        setOpen(false);
        getSubscription();
        getOrders();
        setLoading(false);
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>Cancel Order</DialogTitle>
            {rowInfo?.shippingStatus !== 'LABEL PRINTED' ? (
                <DialogContent>Confirm cancel and full refund on order {rowInfo?.orderNumber}</DialogContent>
            ) : (
                <DialogContent>
                    Order {rowInfo?.orderNumber} is already in production. Only payment authorization will be canceled,
                    <b>order will still arrive.</b>
                </DialogContent>
            )}
            <DialogActions>
                {rowInfo?.shippingStatus === 'LABEL PRINTED' ? (
                    <Button onClick={cancelOrderAuth} disabled={loading === true}>
                        Cancel Auth
                    </Button>
                ) : (
                    <Button onClick={cancelOrderLineItem} disabled={loading === true}>
                        Cancel Order
                    </Button>
                )}
                <Button
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { CancelModal };

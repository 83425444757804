import React, { useEffect, useState, useContext } from 'react';
import { FormComponent } from '../../../../shared';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { UserContext } from '../../../../contexts/UserContext';
import { CycleCountApi } from '../../../../api/cycleCount';

const cycleCountApi = new CycleCountApi();

const SkuCycleCountForm = ({ onSuccess, closeModal, open }) => {
    const [options, setOptions] = useState([]);
    const [error, setError] = useState('');
    const user = useContext(UserContext);

    const createSkuCycleCountRequest = async (formData) => {
        if (!formData.skus.length) {
            setError('Please select at least one sku');
            return;
        }

        const externalSkus = formData.skus.map((item) => item.value);
        const res = await cycleCountApi.createSkuCycleCountRequest(externalSkus, user.getFacilityID());
        if (!res.status) {
            setError(res.msg);
            return;
        }

        onSuccess();
    };

    useEffect(() => {
        const getOptions = async () => {
            const res = await cycleCountApi.getSkuOptions(user.getFacilityID());
            if (!res.status) {
                setError('Failed to get items');
                return;
            }

            setOptions(res.data.items);
        };

        getOptions();
    }, []);

    const formFields = [
        {
            name: 'skus',
            label: 'Skus',
            inputElement: 'autocomplete',
            inputProps: {
                multiple: true,
                opts: options.map((item) => ({
                    value: item.externalSku,
                    text: `Sku ${item.externalSku} - ${item.name}`,
                })),
            },
        },
    ];

    return (
        <Dialog open={open} maxWidth="lg" fullWidth={true} onClose={closeModal}>
            <DialogTitle>Create New Sku Cycle Count</DialogTitle>
            <DialogContent>
                <FormComponent formFields={formFields} onSubmit={createSkuCycleCountRequest} />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </DialogContent>
        </Dialog>
    );
};

export { SkuCycleCountForm };

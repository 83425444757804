import React from 'react';
import { Button } from '@material-ui/core';

const ActionsCell = ({ row, setSelectedRow, setViewActions }) => {
    const handleActionClick = (event) => {
        event.stopPropagation();
        setSelectedRow(row.original);
        setViewActions(true);
    };

    return (
        <div onClick={(event) => event.stopPropagation()}>
            <Button onClick={handleActionClick}>Actions</Button>
        </div>
    );
};

export { ActionsCell };

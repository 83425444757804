import React, { useContext, useEffect, useRef, useState } from 'react';
import { DataTable, Notifications } from '../../../../shared';
import { ActionsCell } from './ActionsCell';
import { ActionModal } from './ActionModal';
import './locationInventoryV2Styles.css';
import { Button, CircularProgress } from '@material-ui/core';
import { WarehouseInventoryV2Api } from '../../../../api/warehouseInventoryV2';
import { UserContext } from '../../../../contexts/UserContext';
import { AdjustCasesAndLooseUnitsAction } from './actions/adjustCasesAndLooseUnitsAction';


const warehouseInventoryV2Api = new WarehouseInventoryV2Api();

const LotTabExpansion = ({ onLinkClicked, lotActive, selectedLotId, onSuccess }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [rows, setRows] = useState([]);
    const [viewActions, setViewActions] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [actions, setActions] = useState([]);
    const [locationsLoading, setLocationsLoading] = useState(false);
    const { user } = useContext(UserContext);
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        getLocationDataForLot();
    }, [selectedLotId]);

    //filter actions based on selectedRow.type
    useEffect(() => {
        if (selectedRow.type !== 'PREP') {
            setActions(actionDefinitions.filter((action) => action.value !== 'prep'));
        } else {
            setActions(actionDefinitions);
        }
    }, [selectedRow]);

    const columns = [
        {
            Header: 'Location',
            accessor: 'locationName',
            Cell: ({ row }) => {
                return (
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => handleLocationLinkClick(row.original.locationName)}
                    >
                        {row.original.locationName}
                    </Button>
                );
            },
        },
        { Header: 'Pallets', accessor: 'pallets' },
        { Header: 'Cases', accessor: 'cases' },
        { Header: 'Loose Units', accessor: 'looseUnits' },
        { Header: 'Total Units', accessor: 'totalUnits' },
        {
            Header: 'Actions',
            Cell: ({ row }) => (
                <ActionsCell row={row} setSelectedRow={setSelectedRow} setViewActions={setViewActions} />
            ),
        },
    ];

    const handleLocationLinkClick = (locationName) => {
        onLinkClicked({ tabIndex: 1, searchBy: 'locationName', searchTerm: locationName });
    };

    const handleModalClose = () => {
        setViewActions(false);
        setSelectedRow({});
    };

    const handleSuccess = () => {
        onSuccess();
    };

    //API call to GET data
    const getLocationDataForLot = async () => {
        if (!locationsLoading && isMounted.current) {
            setLocationsLoading(true);
            const result = await warehouseInventoryV2Api.getLocations(user.facilityID, selectedLotId);
            if (result.status) {
                setRows(
                    result.data.locations.map((location) => {
                        return {
                            locationId: location.locationId,
                            locationName: location.locationName,
                            active: location.numLots > 0,
                            uniqueSkus: location.numSku,
                            lots: location.numLots,
                            pallets: location.numPallets,
                            cases: location.numCases,
                            looseUnits: location.numLooseUnits,
                            totalUnits: location.numTotalUnits,
                            locInvType: location.inventoryType,
                        };
                    })
                );
            } else {
                setNotification({ text: result.msg, level: 'error' });
            }
            setLocationsLoading(false);
        }
    };

    //Action Definitions
    const actionDefinitions = [
        {
            text: 'Prep',
            value: 'prep',
            componentJSX: function () {
                return <span>TODO PUT ACTION FORMS HERE for Prep</span>;
            },
        },
        {
            text: 'Move Lot',
            value: 'moveLot',
            componentJSX: function () {
                return <span>TODO PUT ACTION FORMS HERE for Move Lots</span>;
            },
        },
        {
            text: 'Lot Not in Location',
            value: 'lotNotInLocation',
            componentJSX: function () {
                return <span>TODO PUT ACTION FORMS HERE for Print Case Label</span>;
            },
        },
        {
            text: 'Entire Location Empty',
            value: 'entireLocationEmpty',
            componentJSX: function () {
                return <span>TODO PUT ACTION FORMS HERE for Entire Location Empty</span>;
            },
        },
        {
            text: 'Create Empty Pallet',
            value: 'createEmptyPallet',
            componentJSX: function () {
                return <span>TODO PUT ACTION FORMS HERE for Create Empty Pallet</span>;
            },
        },
        {
            text: 'Adjust Cases and Loose Units',
            value: 'adjustCasesAndLooseUnits',
            componentJSX: function () {
                return (
                    <AdjustCasesAndLooseUnitsAction
                        locationId={selectedRow?.locationId}
                        lotId={selectedLotId}
                        caseNum={selectedRow?.cases}
                        looseUnitNum={selectedRow?.looseUnits}
                        onSuccess={handleSuccess}
                        onCancel={handleModalClose}
                        locInvType={selectedRow?.locInvType}
                    />
                );
            },
        },
        {
            text: 'Shrink Inventory',
            value: 'shrinkInventory',
            componentJSX: function () {
                return <span>TODO PUT ACTION FORMS HERE for Shrink Inventory</span>;
            },
        },
    ];

    return ( lotActive &&
        <div className='expanded-table-div'>
            <Notifications options={notification} />
            {locationsLoading && <CircularProgress color="inherit" size={48} />}
            {!locationsLoading && (
                <DataTable columns={columns} data={rows} editable={false} tableContainerMaxHeight={600} />
            )}
            <ActionModal
                title={`Actions for Location ${selectedRow.locationName}`}
                open={viewActions}
                row={selectedRow}
                actions={actions}
                closeModal={handleModalClose}
            />
        </div>
    );
};

export { LotTabExpansion };

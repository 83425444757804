import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { FormComponent, Notifications } from '../../../shared';
import { marketingApi as api } from '../../../api/marketing';

let ReferralProgram = () => {
    let [error, setError] = useState(null);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [newCustCredit, setNewCustCredit] = useState(null);
    let [advocateCredit, setAdvocateCredit] = useState(null);
    let [minimumOrderValue, setMinimumOrderValue] = useState(null);
    let [creditAmountOptions, setCreditAmountOptions] = useState([
        { text: '$10', value: '10' },
        { text: '$15', value: '15' },
        { text: '$20', value: '20' },
        { text: '$25', value: '25' },
        { text: '$30', value: '30' },
        { text: '$35', value: '35' },
    ]);
    let [termsAndConditions, setTermsAndConditions] = useState(null);

    let formFields = [
        {
            name: 'newCustValue',
            inputElement: 'select',
            label: 'New Customer Credit Dollars',
            gridValue: 6,
            inputProps: { required: true, opts: creditAmountOptions },
        },
        {
            name: 'referrerValue',
            inputElement: 'select',
            label: 'Advocate Credit Dollars',
            gridValue: 6,
            inputProps: { required: true, opts: creditAmountOptions },
        },
        {
            name: 'minimumOrderValue',
            inputElement: 'textField',
            label: 'Minimum Spend',
            gridValue: 6,
            inputProps: { required: true },
        },
        {
            name: 'termsAndConditionsValue',
            inputElement: 'textField',
            label: 'Terms & Conditions',
            gridValue: 6,
            inputProps: { required: false },
        },
    ];

    let getReferralRewards = async () => {
        let response = await api.getReferralRewards();
        if (response.status === false) {
            setNotification({
                text: 'Unable to determine current referral credit rewards ' + response.msg,
                level: 'error',
            });
            return;
        }

        setNewCustCredit(response.data.referralRewards.newCustValue);
        setAdvocateCredit(response.data.referralRewards.referrerValue);
        setTermsAndConditions(response.data.referralRewards.termsAndConditions);
        setMinimumOrderValue(response.data.referralRewards.minimumOrderValue);
    };

    useEffect(() => {
        getReferralRewards();
    }, []);

    return (
        <div>
            <h1>Referral Program</h1>
            <Notifications options={notification} />
            {}
            <div style={{ outline: 0, backgroundColor: 'white' }}>
                <div>
                    {' '}
                    <h3 style={{ display: 'inline-block' }}>Current new customer credit: </h3>{' '}
                    <span id="CustomerCredit">${newCustCredit}</span>{' '}
                </div>
                <div>
                    {' '}
                    <h3 style={{ display: 'inline-block' }}>Current advocate credit: </h3>{' '}
                    <span id="AdvocateCredit">${advocateCredit}</span>{' '}
                </div>
                <div>
                    {' '}
                    <h3 style={{ display: 'inline-block' }}>Current minimum order value: </h3>{' '}
                    <span id="MinimumOrderValue">${minimumOrderValue}</span>{' '}
                </div>
                <div>
                    {' '}
                    <h3 style={{ display: 'inline-block' }}> Current terms and conditions: </h3> {termsAndConditions}{' '}
                </div>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        let response = await api.updateReferralRewards(formData);
                        if (response.status === false) {
                            setNotification({
                                text: 'Unable to update referral credit rewards ' + response.msg,
                                level: 'error',
                            });
                            return;
                        }

                        setNotification({ text: 'Success!', level: 'success' });
                        resetForm();
                        getReferralRewards();
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </div>
    );
};

export { ReferralProgram };

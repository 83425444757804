import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { DataTable, Notifications } from '../../../../shared';
import { ActionsCell } from './ActionsCell';
import { LotTabExpansion } from './lotTabExpansion';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import { StyledSwitch } from '../../../../shared/components/StyledSwitch';
import { ActionModal } from './ActionModal';
import { ChangeExpirationDateAction } from './actions/changeExpirationDateAction';
import { WarehouseInventoryV2Api } from '../../../../api/warehouseInventoryV2';

const warehouseInventoryV2Api = new WarehouseInventoryV2Api();

const LotTab = ({ onLinkClicked, queryParams }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [rows, setRows] = useState([]);
    const [loadingLots, setLoadingLots] = useState(false);
    const [viewActions, setViewActions] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [activeLots, setActiveLots] = useState(true);
    const { user } = useContext(UserContext);
    const [actions, setActions] = useState([]);

    //search bar queryParams effect
    useEffect(() => {
        //TODO handle the filtering of the row data to match queryParams
        //TODO read and verify query params to pass to api call
        handleSearch();
    }, [queryParams, activeLots]);

    //filter actions based on selectedRow.type
    useEffect(() => {
        if (selectedRow?.active) {
            setActions(actionDefinitions.filter((action) => action.value !== 'adjustFromZero'));
        } else {
            setActions(actionDefinitions);
        }
    }, [selectedRow]);

    const handleSearch = async () => {
        //TODO handle the filtering of the row data to match queryPrams
        getLots();
    };

    //Column Definitions
    const columns = [
        { Header: 'Lot Number', accessor: 'lotNumber' },
        { Header: 'Active', accessor: 'active', type: 'checkbox' },
        { Header: 'Item Name', accessor: 'itemName' },
        {
            Header: 'External SKU',
            accessor: 'externalSku',
            Cell: ({ row }) => {
                return (
                    <Button variant="text" color="primary" onClick={() => handleSkuLinkClick(row.original.externalSku)}>
                        {row.original.externalSku}
                    </Button>
                );
            },
        },
        { Header: 'Partner SKU', accessor: 'partnerSku' },
        { Header: 'Expiration Date', accessor: 'expirationDate' },
        { Header: 'Pallets', accessor: 'pallets' },
        { Header: 'Units Per Case', accessor: 'unitsPerCase' },
        { Header: 'Cases', accessor: 'cases' },
        { Header: 'Loose Units', accessor: 'looseUnits' },
        { Header: 'Total Units', accessor: 'totalUnits' },
        { Header: 'Num Locations', accessor: 'numLocations' },
        { Header: 'PO', accessor: 'po' },
        { Header: 'Received Date', accessor: 'receivedDate' },
        { Header: 'Case Number', accessor: 'caseNumber' },
        {
            accessor: 'actions',
            Header: 'Actions',
            Cell: ({ row }) => (
                <ActionsCell row={row} setSelectedRow={setSelectedRow} setViewActions={setViewActions} />
            ),
        },
    ];

    //API call to GET data
    const getLots = async () => {
        setLoadingLots(true);
        const result = await warehouseInventoryV2Api.getLots(user.facilityID);
        if (result.status) {
            setRows(
                result.data.lots.map((lot) => {
                    return {
                        ...lot,
                        looseUnits: lot.numLooseUnits,
                        cases: lot.numCases,
                        pallets: lot.numPallets,
                        po: lot.purchaseOrderId,
                        caseNumber: lot.caseName,
                        active: lot.totalUnits > 0,
                    };
                })
            );
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
        setLoadingLots(false);
    };

    const handleSkuLinkClick = (externalSku) => {
        onLinkClicked({ tabIndex: 2, searchBy: 'sku', searchTerm: externalSku });
    };

    const handleOnRowClicked = (row) => {
        setSelectedRow(row.original);
    };

    const handleModalClose = () => {
        setViewActions(false);
    };

    const handleSuccess = () => {
        setNotification({ text: 'Success', level: 'success' });
        handleModalClose();
        getLots();
    };

    //Action Definitions
    const actionDefinitions = [
        {
            text: 'Bulk Adjust Units per Case',
            value: 'bulkAdjustUnitsPerCase',
            componentJSX: function () {
                return <span>TODO PUT ACTION FORMS HERE for Bulk Adjust Units per Case</span>;
            },
        },
        {
            text: 'Adjust From Zero',
            value: 'adjustFromZero',
            componentJSX: function () {
                return <span>TODO PUT ACTION FORMS HERE for Adjust From Zero</span>;
            },
        },
        {
            text: 'Print Case Label',
            value: 'printCaseLabel',
            componentJSX: function () {
                return <span>TODO PUT ACTION FORMS HERE for Print Case Label</span>;
            },
        },
        {
            text: 'Change Expiration Date',
            value: 'changeExpirationDate',
            componentJSX: function () {
                return (
                    <ChangeExpirationDateAction
                        lotId={selectedRow?.lotNumber}
                        onSuccess={handleSuccess}
                        onCancel={handleModalClose}
                    />
                );
            },
        },
    ];

    const handleActiveLotsChange = (event) => {
        setActiveLots(event.target.checked);
    };

    return (
        <>
            <Notifications options={notification} />
            <div>
                <Typography>Display Only Active Lots</Typography>
                <StyledSwitch checked={activeLots} onChange={handleActiveLotsChange} />
            </div>
            {loadingLots && <CircularProgress color="inherit" size={48} />}
            {!loadingLots && (
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={false}
                    tableContainerMaxHeight={800}
                    stickyHeader={true}
                    expandable={true}
                    ExpansionComponent={
                        <LotTabExpansion
                            selectedLotId={selectedRow?.lotNumber}
                            lotActive={selectedRow?.active}
                            onSuccess={handleSuccess}
                            onLinkClicked={onLinkClicked}
                        />
                    }
                    onRowClicked={handleOnRowClicked}
                />
            )}
            <ActionModal
                title={`Actions for Lot ${selectedRow?.lotNumber}`}
                open={viewActions}
                row={selectedRow}
                actions={actions}
                closeModal={handleModalClose}
            />
        </>
    );
};

export { LotTab };

import React, { useEffect, useState } from 'react';
import { Button, Select, InputLabel, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const ActionModal = ({ actions, closeModal, title, open, row }) => {
    const [selectedAction, setSelectedAction] = useState('');
    useEffect(() => {
        if (!open) {
            handleClose();
        }
    }, [open]);

    const handleActionChange = (event) => {
        const selectedValue = event.target.value;
        const action = actions.find((a) => a.value === selectedValue);
        setSelectedAction(action);
    };

    const handleClose = () => {
        setSelectedAction('');
        closeModal();
    };

    return (
        <Dialog open={open} maxWidth="sm" fullWidth={true} onClose={handleClose}>
            <DialogTitle>
                {title}
                <div style={{ float: 'right' }}>
                    <Button onClick={handleClose}>
                        <CloseIcon />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent>
                <InputLabel shrink={true}>Option</InputLabel>
                <Select native className="input-element-select" onChange={handleActionChange}>
                    <option key="ps" value="ps">
                        Please Select
                    </option>
                    {actions.map((item) => (
                        <option key={item.value} value={item.value}>
                            {item.text}
                        </option>
                    ))}
                </Select>
                <div>{selectedAction?.componentJSX && selectedAction.componentJSX()}</div>
            </DialogContent>
        </Dialog>
    );
};

export { ActionModal };

import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { DataTable, Notifications } from '../../../../shared';
import { SkuTabExpansion } from './skuTabExpansion';
import { WarehouseInventoryV2Api } from '../../../../api/warehouseInventoryV2';
import { CircularProgress } from '@material-ui/core';

const warehouseInventoryV2Api = new WarehouseInventoryV2Api();

const SkuTab = ({ onLinkClicked, queryParams }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [rows, setRows] = useState([]);
    const [loadingSkus, setLoadingSkus] = useState(false);
    const [viewActions, setViewActions] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const user = useContext(UserContext);

    //search bar queryParams effect
    useEffect(() => {
        handleSearch();
    }, [queryParams]);

    const handleSearch = async () => {
        getSkus();
    };

    //Column Definitions
    const columns = [
        { Header: 'External SKU', accessor: 'externalSku' },
        { Header: 'Partner SKU', accessor: 'partnerSku' },
        { Header: 'Item Name', accessor: 'itemName' },
        { Header: 'Pallets', accessor: 'numPallets' },
        { Header: 'Cases', accessor: 'numCases' },
        { Header: 'Loose Units', accessor: 'numLooseUnits' },
        { Header: 'Total Units', accessor: 'numTotalUnits' },
    ];

    //API call to GET data
    const getSkus = async () => {
        setLoadingSkus(true);
        const result = await warehouseInventoryV2Api.getSkus(user.getFacilityID());
        if (result.status) {
            setRows(
                result.data.skus.map((sku) => {
                    return {
                        ...sku,
                    };
                })
            );
        } else {
            setNotification({ text: result.msg, level: 'error' });
        }
        setLoadingSkus(false);
    };

    const handleOnRowClicked = (row) => {
        setSelectedRow(row.original);
    };
    

    const handleSuccess = () => {
        setNotification({ text: 'Success', level: 'success' });
        getSkus();
    };

    return (
        <>
            <Notifications options={notification} />
            {loadingSkus && <CircularProgress color="inherit" size={48} />}
            {!loadingSkus && (
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={false}
                    tableContainerMaxHeight={800}
                    stickyHeader={true}
                    expandable={true}
                    ExpansionComponent={
                        <SkuTabExpansion
                            externalSku={selectedRow?.externalSku}
                            onSuccess={handleSuccess}
                            type={selectedRow?.type}
                            onLinkClicked={onLinkClicked}
                        />
                    }
                    onRowClicked={handleOnRowClicked}
                />
            )}
        </>
    );
};

export { SkuTab };

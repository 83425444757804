import React, { useState } from 'react';
import { FormComponent } from '../../shared';
import { InputAdornment, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const SearchBarV2 = ({
    onSubmit,
    searchByOptions,
    onClearSearch = null,
    searchBy = undefined,
    searchTerm = undefined,
}) => {
    const [submitted, setSubmitted] = useState(false);
    const [resetFormData, setResetFormData] = useState(null);
    const [searchTermElement, setSearchTermElement] = useState({ type: 'textField', inputProps: { required: true } });

    const clearSearch = () => {
        setSubmitted(false);
        if (resetFormData instanceof Function) {
            resetFormData();
        }
        onClearSearch();
    };

    const clearSearchAdornment = () => {
        if (!submitted) {
            return null;
        }
        return (
            <InputAdornment position="end">
                <IconButton onClick={clearSearch}>
                    <ClearIcon />
                </IconButton>
            </InputAdornment>
        );
    };

    const searchFormFields = [
        {
            name: 'searchBy',
            inputElement: 'select',
            initialValue: searchBy ? searchBy : searchByOptions[0].value,
            gridValue: 3,
            inputProps: { required: true, opts: searchByOptions },
        },
        {
            name: 'searchTerm',
            label: ' ',
            inputElement: searchTermElement.type, // this needs to be dynamic
            gridValue: 8,
            inputProps: searchTermElement.inputProps,
            InputProps: { endAdornment: onClearSearch instanceof Function ? clearSearchAdornment() : null },
            initialValue: searchTerm ? searchTerm : '',
        },
    ];

    return (
        <FormComponent
            formFields={searchFormFields}
            button={{ text: 'Search', gridValue: 1 }}
            onSubmit={async (formData, resetForm) => {
                setResetFormData(() => () => resetForm());
                setSubmitted(true);
                onSubmit(formData);
            }}
            onFormDataChange={(formData, __) => {
                const searchByOption = searchByOptions.find(
                    (searchByOption) => searchByOption.value === formData.searchBy
                );

                if (searchByOption) {
                    if (searchByOption.searchTermElementType === 'select') {
                        setSearchTermElement({
                            type: searchByOption.searchTermElementType,
                            inputProps: { required: true, opts: searchByOption.opts },
                        });
                    } else {
                        setSearchTermElement({
                            type: searchByOption.searchTermElementType,
                            inputProps: { required: true },
                        });
                    }
                }
            }}
        />
    );
};

export { SearchBarV2 };

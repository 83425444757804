import React, { useState, useEffect } from 'react';
import { DataTable } from '../../../../shared';
import { makeStyles } from '@material-ui/core/styles';
import { CycleCountApi } from '../../../../api/cycleCount';

const cycleCountApi = new CycleCountApi();

const SkuCycleCountExpansion = ({ parentRow, setNotification }) => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        const getRows = async () => {
            if (parentRow.original.status === 'NEW') {
                return;
            }

            const res = await cycleCountApi.getSkuCycleCountRequestEntityResults(
                parentRow.original.inventoryCycleCountRequestEntityID
            );
            if (!res.status) {
                setNotification({ text: 'Failed to get results', level: 'error' });
                return;
            }

            setRows(res.data.results);
        };

        getRows();
    }, [parentRow.original.inventoryCycleCountRequestEntityID]);

    const useStyles = makeStyles(() => ({
        expansionComponent: {
            backgroundColor: '#f4f5fb',
        },
    }));
    const classes = useStyles();

    const columns = [
        { Header: 'Location', accessor: 'locationName' },
        { Header: 'Units Expected', accessor: 'unitsExpected' },
        { Header: 'Units Counted', accessor: 'unitsCounted' },
        { Header: 'Unit Accuracy', accessor: 'unitAccuracy' },
    ];

    return (
        <div className={classes.expansionComponent}>
            <DataTable columns={columns} data={rows} />;
        </div>
    );
};

export { SkuCycleCountExpansion };

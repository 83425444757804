import { mmFetchWithResponse } from './mmFetchWithResponse';

class WarehouseInventoryV2Api {
    constructor() {
        this.baseUrl = 'api/warehouse/v2/inventory/';
    }

    async getPallets(facilityId) {
        const url = `${this.baseUrl}pallets?facilityId=${facilityId}`;
        return await mmFetchWithResponse(url, { method: 'GET' });
    }

    async getPalletsByLocationId(locationId, facilityId) {
        const url = `${this.baseUrl}pallets/location/${locationId}?facilityId=${facilityId}`;
        return await mmFetchWithResponse(url, { method: 'GET' });
    }

    async getPalletsByLocationIdandLotId(locationId, lotId, facilityId) {
        const url = `${this.baseUrl}pallets/location/${locationId}/lot/${lotId}?facilityId=${facilityId}`;
        return await mmFetchWithResponse(url, { method: 'GET' });
    }

    async getLots(facilityId, palletId = undefined, locationId = undefined, externalSku = undefined, type = undefined) {
        let url = `${this.baseUrl}lots?facilityId=${facilityId}`;
        if (palletId !== undefined) {
            url += `&palletId=${palletId}`;
        }
        if (locationId !== undefined) {
            url += `&locationId=${locationId}`;
        }
        if (externalSku !== undefined && type !== undefined) {
            url += `&externalSku=${externalSku}&type=${type}`;
        }
        return await mmFetchWithResponse(url, { method: 'GET' });
    }

    async getLocations(facilityId, lotId = undefined) {
        let url = `${this.baseUrl}locations?facilityId=${facilityId}`;
        if (lotId != undefined) {
            url += `&lotId=${lotId}`;
        }
        return await mmFetchWithResponse(url, { method: 'GET' });
    }

    async getSkus(facilityId) {
        const url = `${this.baseUrl}skus?facilityId=${facilityId}`;
        return await mmFetchWithResponse(url, { method: 'GET' });
    }
}

export { WarehouseInventoryV2Api };

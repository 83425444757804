import React, { useState, useEffect } from 'react';
import { FormComponent, Notifications, DataTable } from '../../../shared';
import { MassCredit } from '../../compensations/components/massCredit';
import { MassRefund } from './massRefund';
import { MassCancel } from './massCancel';
import { Grid } from '@material-ui/core';
import { customerApi as api } from '../../../api/customer';

const CustomerManagerBulkTools = () => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [formView, setFormView] = useState(null);
    const [pastUploads, setPastUploads] = useState([]);

    //commenting out bulk refund for now since the backend logic for refunds is not complete
    const formOptions = [
        { text: 'Bulk Refunds', value: 'bulkRefund' },
        { text: 'Bulk Cancel', value: 'bulkCancel' },
        { text: 'Bulk Credit', value: 'bulkCredit' },
    ];

    const formFields = [
        {
            name: 'bulkTools',
            inputElement: 'select',
            label: 'Bulk Tools',
            value: formView ? formView : null,
            gridValue: 5,
            inputProps: {
                required: true,
                opts: formOptions,
                onChange: (e) => setFormView(e.target.value),
            },
        },
    ];

    const formRouter = {
        bulkRefund: <MassRefund />,
        bulkCancel: <MassCancel />,
        bulkCredit: <MassCredit />,
    };

    const getPastCustomerUploads = async () => {
        const res = await api.getBulkCustomerUploads();
        if (!res.status) {
            setNotification({ text: 'Failed to get past upload history.', level: 'error' });
            return;
        }
        setPastUploads(res.data?.data);
    };

    useEffect(() => {
        getPastCustomerUploads();
    }, []);

    return (
        <div>
            <h1>Customer Manager Bulk Upload Tools</h1>
            <Grid>
                <Notifications options={notification} />
                <FormComponent formFields={formFields} hideSubmit={true} />
                {formRouter[formView] ? (
                    formRouter[formView]
                ) : (
                    <div>
                        <h3>Please Choose a Bulk Upload Tool</h3>
                    </div>
                )}
                {pastUploads ? (
                    <DataTable
                        title={`Last Submitted Uploads`}
                        data={pastUploads}
                        columns={[
                            { Header: 'User', accessor: 'authUser' },
                            { Header: 'Date', accessor: 'uploadedAt' },
                            { Header: 'Upload Type', accessor: 'uploadType' },
                            {
                                Header: 'Results File',
                                accessor: 's3Url',
                                Cell: ({ cell: { value: initialValue }, row }) => {
                                    const resultFile = row.original.s3ObjectURL;
                                    if (resultFile) {
                                        return (
                                            <a
                                                href={resultFile}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ wordBreak: 'break-all' }}
                                            >
                                                Download
                                            </a>
                                        );
                                    }
                                    return null;
                                },
                            },
                            { Header: 'Num Success', accessor: 'numSuccess' },
                            { Header: 'Num Fail', accessor: 'numFailure' },
                        ]}
                    />
                ) : (
                    <></>
                )}
            </Grid>
        </div>
    );
};

export { CustomerManagerBulkTools };

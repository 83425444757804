import React from 'react';
import { FormComponent } from '../../../../shared';
import { Grid } from '@material-ui/core';

const LocationAccuracyTimeframeSelection = ({ onSubmit, initialStartDate, initialEndDate }) => {
    const formFields = [
        {
            inputElement: () => {
                return (
                    <div>
                        <strong>Timeframe:</strong>
                    </div>
                );
            },
            gridValue: 2,
        },
        {
            name: 'start',
            inputElement: 'date',
            initialValue: initialStartDate,
            gridValue: 4,
            inputProps: { required: true },
        },
        {
            name: 'end',
            inputElement: 'date',
            initialValue: initialEndDate,
            gridValue: 4,
            inputProps: { required: true },
        },
    ];
    return (
        <Grid container style={{ justifyContent: 'center' }}>
            <Grid item xs={12} sm={8}>
                <FormComponent
                    formFields={formFields}
                    button={{ text: 'Ok', gridValue: 2 }}
                    onSubmit={async (formData) => {
                        await onSubmit(formData);
                    }}
                />
            </Grid>
        </Grid>
    );
};

export { LocationAccuracyTimeframeSelection };

import React, { useEffect, useState, useContext } from 'react';
import { DataTable } from '../../../../shared';
import { Notifications } from '../../../../shared';
import { Button } from '@material-ui/core';
import { LocationIssuesModal } from './locationIssuesModal';
import { UserContext } from '../../../../contexts/UserContext';
import { CycleCountApi } from '../../../../api/cycleCount';

const cycleCountApi = new CycleCountApi();

const LocationIssuesTab = () => {
    const [rows, setRows] = useState([]);
    const [currentIssue, setCurrentIssue] = useState(null);
    const [notification, setNotification] = useState({ text: '', level: '' });
    const user = useContext(UserContext);

    useEffect(() => {
        getLocationIssues();
    }, []);

    const resolveCurrentIssue = async (id) => {
        setCurrentIssue(null);

        const res = await cycleCountApi.resolveIssue(currentIssue.eventID);
        if (!res.status) {
            setNotification({ text: res.msg || 'Failed to resolve issue', level: 'error' });
            return;
        }

        setNotification({ text: 'Issue Resolved', level: 'success' });
        getLocationIssues();
    };

    const columns = [
        { Header: 'Location', accessor: 'locationName' },
        { Header: 'Datetime Ended', accessor: 'timestamp' },
        { Header: 'User', accessor: 'authUser' },
        { Header: 'Issue', accessor: 'issueType' },
        { Header: 'Details', accessor: 'details' },
        {
            Header: 'Resolved?',
            Cell: ({ row }) => {
                return (
                    <Button
                        onClick={() => {
                            setCurrentIssue(row.original);
                        }}
                    >
                        Accept?
                    </Button>
                );
            },
        },
    ];

    const getLocationIssues = async () => {
        const res = await cycleCountApi.getLocationIssues(user.getFacilityID());
        if (!res.status) {
            setNotification({ text: res.msg, level: 'error' });
            return;
        }

        setRows(res.data.results);
    };

    return (
        <>
            <Notifications options={notification} />
            <LocationIssuesModal
                open={!!currentIssue}
                handleClose={() => {
                    setCurrentIssue(null);
                }}
                handleConfirm={() => {
                    resolveCurrentIssue();
                }}
                issue={currentIssue}
            />
            <DataTable columns={columns} data={rows} editable={false} tableContainerMaxHeight={800} />
        </>
    );
};

export { LocationIssuesTab };

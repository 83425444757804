import React, { useEffect, useState } from 'react';
import { FormComponent, Notifications } from '../../../../../shared';
import { warehouseApi } from '../../../../../api/warehouse';
import { Button, Typography } from '@material-ui/core';

const ChangeExpirationDateAction = ({ lotId, onSuccess, onCancel }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [currentFormData, setCurrentFormData] = useState({});

    useEffect(() => {
        if (!(onSuccess instanceof Function)) {
            console.error('ChangeExpirationDateAction intialized incorrectly onSuccess is not a function');
        }
        if (!(onCancel instanceof Function)) {
            console.error('ChangeExpirationDateAction initialized incorrectly onCancel is not a function');
        }
    }, [onSuccess, onCancel]);

    const formFields = [
        {
            name: 'expiresOn',
            label: 'Expires On',
            inputElement: 'date',
            gridValue: 4,
            inputProps: { required: true },
        },
    ];

    const handleSubmit = async (formData, resetForm) => {
        const expiresOn = formData.expiresOn;
        const expiresOnDate = new Date(expiresOn);
        const today = new Date();
        if (expiresOnDate < today) {
            setShowConfirmation(true);
            return;
        }
        await handleConfirm();
    };

    const handleConfirm = async () => {
        const expiresOn = currentFormData.expiresOn;
        const result = await warehouseApi.updateLot(lotId, {
            expiresOn,
        });
        if (!result.status) {
            setNotification({ text: result.msg, level: 'error' });
        } else {
            if (onSuccess instanceof Function) {
                onSuccess();
            }
        }
    };

    const handleCancel = () => {
        setShowConfirmation(false);
        onCancel();
    };

    const handleFormDataChange = (formData) => {
        setCurrentFormData(formData);
    };

    return (
        <>
            <Notifications options={notification} />
            <h3>Change Expiration Date</h3>
            <FormComponent
                formFields={formFields}
                onSubmit={handleSubmit}
                button={{ text: 'Submit', gridValue: 1 }}
                onFormDataChange={handleFormDataChange}
            />
            {showConfirmation && (
                <>
                    <Typography style={{ color: 'red', marginBottom: 10 }}>
                        You are trying to update the Expiration Date to a date that has already passed
                    </Typography>
                    <Button onClick={handleConfirm} color="primary" variant="outlined">
                        Confirm
                    </Button>
                    <Button onClick={handleCancel} variant="outlined" style={{ color: 'red', marginLeft: 10 }}>
                        Cancel
                    </Button>
                </>
            )}
        </>
    );
};

export { ChangeExpirationDateAction };

import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

const LocationIssuesModal = ({ issue, open, handleClose, handleConfirm }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Resolve Issue</DialogTitle>
            <DialogContent>
                Has {issue?.issueType} with Location {issue?.locationName} been resolved?
                <DialogActions>
                    <Button onClick={handleConfirm}>Yes</Button>
                    <Button onClick={handleClose}>No</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export { LocationIssuesModal };

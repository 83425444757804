import moment from 'moment';
import { mmFetch } from './mmFetch';
import { mmFetchWithResponse } from './mmFetchWithResponse';

function objToQueryString(obj) {
    let str = [];
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            str.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
    }
    return str.join('&');
}

let shippingApi = {
    abandonBatches: async (packLineID) => {
        let url = `api/shipping/v1/batchelor/abandon`;
        let response = await mmFetchWithResponse(url, {
            body: JSON.stringify({ packLineID }),
            method: 'PUT',
        });
        return response;
    },
    abandonLineHaulBatch: async (packLineID, lineHaulID) => {
        let url = `api/shipping/v2/batchelor/abandon`;
        let response = await mmFetchWithResponse(url, {
            body: JSON.stringify({ packLineID, lineHaulID }),
            method: 'PUT',
        });
        return response;
    },
    cancelAddlLabel: async (shipmentID) => {
        let url = `api/shipping/v2/label/${shipmentID}/cancel`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    claimBatches: async (data) => {
        let url = `api/shipping/v3/batchelor/claim`;
        let response = await mmFetchWithResponse(url, {
            body: JSON.stringify(data),
            method: 'PUT',
        });
        return response;
    },
    dbiDeepDive: async (orderNum, shipmentID) => {
        let url = `api/bringg/v1/deepDive?`;

        if (orderNum != null) {
            url += 'orderNum=' + orderNum;
        } else {
            url += 'shipmentID=' + shipmentID;
        }

        let response = await mmFetchWithResponse(url);
        return response;
    },
    getDBIRoutes: async (lineHaulID) => {
        let url = `api/shipping/v3/batchelor/dbiRoutes?lineHaulID=${lineHaulID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getFailedShipments: async (facilityID) => {
        let url = `api/shipping/v1/shipment/failed?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getShipmentAddress: async (id) => {
        let url = `api/shipping/v1/shipment/address/${id}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getShipmentLabelPDF: async (shipmentID) => {
        let url = `api/shipping/v1/shipment/${shipmentID}/pdf`;
        try {
            let response = await mmFetch(url);
            if (response.ok === true) {
                var blob = await response.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                let filename = response.headers.get('content-disposition').split('filename=')[1];
                link.download = filename ?? `shipment_${shipmentID}.pdf`;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(response);
                }, 200);
            }
            return response.ok ? true : false;
        } catch (e) {
            console.log('[downloadPDF] error', e.toString());
        }
    },
    downloadPickupLabel: async (shipmentID) => {
        let url = `api/shipping/v3/pickup/${shipmentID}`;
        try {
            let response = await mmFetch(url);
            if (response.ok === true) {
                var blob = await response.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                let filename = response.headers.get('content-disposition').split('filename=')[1];
                link.download = filename ?? `shipment_${shipmentID}.pdf`;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(response);
                }, 200);
                return {
                    status: true,
                };
            } else {
                return await response.json();
            }
        } catch (e) {
            console.log('[downloadPDF] error', e.toString());
        }
    },
    downloadPickupFacilityLabels: async (facilityID) => {
        let url = `api/shipping/v3/pickupFacility?facilityID=${facilityID}`;
        try {
            let response = await mmFetch(url);
            if (response.ok === true) {
                var blob = await response.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                let filename = response.headers.get('content-disposition').split('filename=')[1];
                link.download = filename ?? `facilitypickup_${facilityID}.pdf`;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(response);
                }, 200);
            }
            return response.ok ? true : false;
        } catch (e) {
            console.log('[downloadPDF] error', e.toString());
        }
    },
    downloadPickupCrossDockLabels: async (hub) => {
        let url = `api/shipping/v3/pickupCrossDock?hub=${hub}`;
        try {
            let response = await mmFetch(url);
            if (response.ok === true) {
                var blob = await response.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                let filename = response.headers.get('content-disposition').split('filename=')[1];
                link.download = filename ?? `crossdockpickup_${hub}.pdf`;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(response);
                }, 200);
            }
            return response.ok ? true : false;
        } catch (e) {
            console.log('[downloadPDF] error', e.toString());
        }
    },
    updateShipmentAddress: async (id, sendParams) => {
        let url = `api/shipping/v1/shipment/address/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    getLineHauls: async (facilityID, packGroupID = null) => {
        let url = `api/shipping/v2/batchelor/lineHauls?facility=${facilityID}`;
        if (packGroupID != null) {
            url += `&packGroupID=${packGroupID}`;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPackLinesWithClaims: async (facilityID) => {
        let url = `api/shipping/v1/batchelor/packLines?facility=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPrintedNotPacked: async (facilityID, hours) => {
        let url = `api/shipping/v1/batchelor/printedNotPacked?facility=${facilityID}&hours=${hours}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getShipmentInfo: async (shipmentID) => {
        let url = `api/shipping/v1/shipment/${shipmentID}/info`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getSpawnedShipments: async (facilityID) => {
        let url = `api/shipping/v2/label/new?facilityID=` + facilityID;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    printEntireWave: async (packLineID, multiLine = false) => {
        let url = `api/shipping/v3/batchelor/printAll?packLineID=${packLineID}`;
        if (multiLine) {
            url += '&multiLine=true';
        }

        let respObj = {
            status: true,
            data: {},
            msg: '',
        };

        try {
            let response = await mmFetch(url);

            if (response.ok === true) {
                var blob = await response.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                if (multiLine && blob.type === 'application/json') {
                    return respObj;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                let filename = response.headers.get('content-disposition').split('filename=')[1];
                link.download = filename ?? 'unknown_wave.pdf';
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(response);
                }, 200);
            } else {
                let decodedResp = await response.json();
                respObj.msg = decodedResp.msg;
                respObj.status = false;
            }
        } catch (e) {
            console.log('[printEntireWave] error', e.toString());
            respObj.msg = e.toString();
            respObj.status = false;
        }

        return respObj;
    },
    purchaseTestLabel: async (formData) => {
        let url = `api/shipping/v2/label/test`;
        try {
            let response = await mmFetch(url, {
                method: 'POST',
                body: JSON.stringify(formData),
            });
            if (response.ok === true) {
                var blob = await response.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = 'testlabel.pdf';
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(response);
                }, 200);
            }
            return response.ok;
        } catch (e) {
            console.log('[downloadPDF] error', e.toString());
        }
    },
    purchaseShipment: async (shipmentID) => {
        let url = `api/shipping/v1/shipment/${shipmentID}/purchase`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
        });
        return response;
    },
    resyncBringg: async (orderNum) => {
        let url = `api/bringg/v1/resync`;
        let response = await mmFetchWithResponse(url, {
            body: JSON.stringify({
                orderNum: orderNum,
            }),
            method: 'POST',
        });
        return response;
    },
    replaceShippingLabel: async (shipmentID, carrier, hub) => {
        let url = `api/shipping/v2/label/${shipmentID}/replace`;
        let response = await mmFetchWithResponse(url, {
            body: JSON.stringify({
                carrier: carrier,
                hub: hub,
            }),
            method: 'PUT',
        });
        return response;
    },
    resetShipmentsToLabelPurchased: async (shipmentIDs) => {
        let url = `api/shipping/v1/reset`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ shipmentIDs }),
        });
        return response;
    },
    getPickupShipments: async (purchased, facilityID) => {
        let url = `api/shipping/v3/pickup?facilityID=${facilityID}`;
        if (purchased) {
            url += '&purchased=true';
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getUnprintedPickupDocks: async (facilityID) => {
        let url = `api/shipping/v3/pickupUnpackedDock?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getVipShipments: async (purchased, facilityID) => {
        let url = `api/shipping/v1/vip?facilityID=${facilityID}`;
        if (purchased) {
            url += '&purchased=true';
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    downloadLPN: async (shipmentID) => {
        let url = `api/shipping/v1/shipment/${shipmentID}/pdf`;
        try {
            let response = await mmFetch(url);
            if (response.ok === true) {
                var blob = await response.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = 'vip-' + shipmentID + '.pdf';
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(response);
                }, 200);
            }
            return response.ok ? true : false;
        } catch (e) {
            console.log('[downloadPDF] error', e.toString());
        }
    },
    markVipOrderPrinted: async (shipmentID) => {
        let url = `api/shipping/v1/vip/${shipmentID}/printed`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
        });
        return response;
    },
    getCarriersForOrder: async (orderNum) => {
        let url = `api/shipping/v1/shipment/${orderNum}/carriers`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    spawnNewShipment: async (shipmentID, cubeLevelID, facilityID) => {
        let url = `api/shipping/v2/label/${shipmentID}/new`;
        let response = await mmFetchWithResponse(url, {
            body: JSON.stringify({
                cubeLevelID: cubeLevelID,
                facilityID: facilityID,
            }),
            method: 'POST',
        });
        return response;
    },
    getStuckShipments: async () => {
        let url = `api/shipping/v2/batchelor/stuck`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    resetStuckShipments: async () => {
        let url = `api/shipping/v2/batchelor/stuck`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    uploadAddressesForCarrierShipLabels: async (data) => {
        let formData = new FormData();
        formData.append('addressFile', data.file);
        formData.append('facilityID', data.facilityID);
        formData.append('carrier', data.carrier);
        formData.append('shipDate', data.shipDate);

        let url = 'api/shipping/v1/importAddressesForCarrierLabels';
        return mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
    },
    confirmAddressesForCarrierShipLabels: async (key) => {
        let url = `api/shipping/v1/confirmAddressesForCarrierLabels`;
        return mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ key }),
        });
    },
};

export { shippingApi };

import React, { useEffect, useState, useContext } from 'react';
import { DataTable, Notifications } from '../../../../shared';
import { SkuCycleCountExpansion } from './skuCycleCountExpansion';
import { SkuCycleCountForm } from './skuCycleCountForm';
import { UserContext } from '../../../../contexts/UserContext';
import { CycleCountApi } from '../../../../api/cycleCount';

const cycleCountApi = new CycleCountApi();

const SkuCycleCountTab = () => {
    const user = useContext(UserContext);
    const [rows, setRows] = useState([]);
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [modalOpen, setModalOpen] = useState(false);

    const getSkuCycleCountRows = async () => {
        const res = await cycleCountApi.getSkuCycleCountResults(user.getFacilityID());
        if (!res.status) {
            setNotification({ level: 'error', message: res.msg });
            return;
        }

        setRows(res.data.results);
    };

    useEffect(() => {
        getSkuCycleCountRows();
    }, []);

    const columns = [
        { Header: 'Cycle Count ID', accessor: 'inventoryCycleCountRequestID' },
        { Header: 'Date Requested', accessor: 'dateRequested' },
        { Header: 'Date Started', accessor: 'dateStarted' },
        { Header: 'Date Ended', accessor: 'dateEnded' },
        { Header: 'Manager', accessor: 'authUser' },
        { Header: 'Status', accessor: 'status' },
        { Header: 'Sku', accessor: 'sku' },
        { Header: 'Num Locations', accessor: 'numLocations' },
        { Header: 'Units Expected', accessor: 'unitsExpected' },
        { Header: 'Units Counted', accessor: 'unitsCounted' },
        { Header: 'Unit Accuracy', accessor: 'unitAccuracy' },
    ];

    const handleCreateSuccess = () => {
        setNotification({ text: 'Cycle count request created', level: 'success' });
        setModalOpen(false);
        getSkuCycleCountRows();
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const toolbarActions = [
        {
            name: 'Create New Sku Cycle Count',
            action: () => {
                setModalOpen(true);
            },
        },
    ];

    return (
        <>
            <Notifications options={notification} />
            <SkuCycleCountForm onSuccess={handleCreateSuccess} closeModal={handleCloseModal} open={modalOpen} />
            <DataTable
                columns={columns}
                data={rows}
                editable={false}
                tableContainerMaxHeight={800}
                expandable={true}
                toolbarActions={toolbarActions}
                ExpansionComponent={<SkuCycleCountExpansion setNotification={setNotification} />}
            />
        </>
    );
};

export { SkuCycleCountTab };

import React, { useContext, useEffect, useState } from 'react';
import { Notifications } from '../../../../../shared';
import { warehouseApi } from '../../../../../api/warehouse';
import { Button, CircularProgress } from '@material-ui/core';
import printJS from 'print-js';

const PrintAllCaseLabelsAction = ({ palletId, onSuccess }) => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!(onSuccess instanceof Function)) {
            console.error('PrintAllCaseLabelsAction intialized incorrectly onSuccess is not a function');
        }
    }, [onSuccess]);

    const fileReaderCheck = (fr, tmpPDF) => {
        if (tmpPDF instanceof Blob) {
            fr.readAsDataURL(tmpPDF);
        } else {
            setNotification({text: 'There was an issue printing the pdf', level: 'error'})
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        const result = await warehouseApi.downloadCaseTags({ palletID: palletId });
        setLoading(false);
        if (result) {
            var fr = new FileReader();
            fr.addEventListener(
                'load',
                () => {
                    const tmpPDFdata = fr.result.substr(fr.result.indexOf('base64,') + 7);
                    printJS({ printable: tmpPDFdata, type: 'pdf', base64: true });
                },
                false
            );
            fileReaderCheck(fr, result);
        } else {
            setNotification({ text: 'There was an issue printing the pdf', level: 'error' });
        }
    };

    return (
        <div>
            <Notifications options={notification} />
            <Button onClick={handleSubmit} disabled={loading}>
                {loading ? <CircularProgress size={24}/> : 'Print All Case Labels'}
            </Button>
        </div>
    );
};

export { PrintAllCaseLabelsAction };

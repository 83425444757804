import React, { useState, useEffect, useContext } from 'react';
import { Button, Checkbox, Paper, Tabs, Tab, Select, MenuItem, Grid } from '@material-ui/core';
import { DataTable, TabPanel, FormComponent, Notifications } from '../../../shared';
import { shippingApi } from '../../../api/shipping';
import { UserContext } from '../../../contexts/UserContext';
import moment from 'moment';

let PickupBoxes = () => {
    const [unprintedRows, setUnprintedRows] = useState([]);
    const [printedRows, setPrintedRows] = useState([]);
    const user = useContext(UserContext);
    const [facilityID, setFacilityID] = useState(user.getFacilityID());
    const [unprintedDocks, setUnprintedDocks] = useState([]);
    const [selectedDock, setSelectedDock] = useState('');
    const [notification, setNotification] = useState({ text: null, level: null });
    const [loading, setLoading] = useState(false);

    let getUnprintedPickupOrders = async () => {
        let result = await shippingApi.getPickupShipments(true, facilityID);
        if (result.status === true) {
            setUnprintedRows(
                result.data.rows.map((item) => {
                    item.createdAt = moment(item.createdAt).local().format('MM/DD/YYYY h:mm a');
                    return item;
                })
            );
        }
    };

    let getUnprintedDocks = async () => {
        let result = await shippingApi.getUnprintedPickupDocks(facilityID);
        if (result.status === true) {
            setUnprintedDocks(result.data.rows);
        }
    };

    let getPrintedPickupOrders = async () => {
        let result = await shippingApi.getPickupShipments(false, facilityID);
        if (result.status === true) {
            setPrintedRows(
                result.data.rows.map((item) => {
                    item.createdAt = moment(item.createdAt).local().format('MM/DD/YYYY h:mm a');
                    return item;
                })
            );
        }
    };

    useEffect(() => {
        getUnprintedPickupOrders();
        getPrintedPickupOrders();
    }, []);

    useEffect(() => {
        getUnprintedDocks();
    }, [unprintedRows]);

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'createdAt', Header: 'Created At (UTC' + moment().format('Z') + ')' },
        { accessor: 'shopifyOrderNumber', Header: 'Order Number' },
        { accessor: 'pickupLocationType', Header: 'Type' },
        { accessor: 'pickupLocation', Header: 'Location' },
        { accessor: 'arrivalDay', Header: 'Pack Day' },
        {
            accessor: 'linkToPDF',
            Header: 'PDF',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        className="download-link"
                        onClick={async () => {
                            let res = await shippingApi.downloadPickupLabel(row.original.id);
                            if (!res.status) {
                                setNotification({ text: res.msg, level: 'error' });
                            }
                            getUnprintedPickupOrders();
                            getPrintedPickupOrders();
                        }}
                    >
                        Download
                    </Button>
                );
            },
        },
    ];
    return (
        <div>
            <Notifications options={notification} />
            <Grid container>
                <Grid item md={4} style={{ textAlign: 'left', paddingLeft: '40px' }}>
                    <Button
                        className="download-link"
                        disabled={loading}
                        onClick={async () => {
                            setLoading(true);
                            let res = await shippingApi.downloadPickupFacilityLabels(facilityID);
                            setLoading(false);
                            if (res !== true) {
                                setNotification({ text: 'Error getting labels', level: 'error' });
                            }
                            getUnprintedPickupOrders();
                            getPrintedPickupOrders();
                        }}
                    >
                        Print All Facility
                    </Button>
                </Grid>
                <Grid item md={4} style={{ textAlign: 'left', paddingLeft: '40px' }}>
                    <Select onChange={(event) => setSelectedDock(event.target.value)}>
                        {unprintedDocks.map((item, index) => (
                            <MenuItem key={index} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item md={4} style={{ textAlign: 'left', paddingLeft: '40px' }}>
                    <Button
                        className="download-link"
                        disabled={loading}
                        onClick={async () => {
                            setLoading(true);
                            let res = await shippingApi.downloadPickupCrossDockLabels(selectedDock);
                            setLoading(false);
                            if (res !== true) {
                                setNotification({ text: 'Error getting labels', level: 'error' });
                            }
                            getUnprintedPickupOrders();
                            getPrintedPickupOrders();
                        }}
                    >
                        Print Cross Dock
                    </Button>
                </Grid>
            </Grid>
            <DataTable title="Ready To Print" columns={columns} data={unprintedRows} editable={false} />

            <DataTable title="Printed" columns={columns} data={printedRows} editable={false} />
        </div>
    );
};

export { PickupBoxes };

import { styled, Switch } from '@material-ui/core';

const StyledSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase': {
        top: 7,
    },
    '& .MuiSwitch-thumb': {},
    '& .MuiSwitch-track': {},
}));

export { StyledSwitch };

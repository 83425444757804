import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, Button, Box } from '@material-ui/core';

const LocationInventorySearchBar = ({ queryParams, onSearch }) => {
    const [formData, setFormData] = useState({
        searchBy: queryParams.searchBy ?? '',
        searchTerm: queryParams.searchTerm ?? '',
    });

    useEffect(() => {
        setFormData({
            searchBy: queryParams.searchBy ?? '',
            searchTerm: queryParams.searchTerm ?? '',
        });
    }, [queryParams]);

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        onSearch(formData.searchBy, formData.searchTerm);
    };

    const handleClear = () => {
        setFormData({
            searchBy: '',
            searchTerm: '',
        });
        onSearch('', '');
    };

    const hasFormData = formData.searchBy !== '' || formData.searchTerm !== '';

    return (
        <Box>
            <form
                component="form"
                onSubmit={onSubmit}
                style={{
                    display: 'grid',
                    gridTemplateColumns: '200px 200px 50px 50px',
                    gap: '16px',
                    justifyContent: 'center',
                }}
            >
                <TextField
                    select
                    label="Search By:"
                    name="searchBy"
                    value={formData.searchBy}
                    onChange={onInputChange}
                    variant="outlined"
                    sx={{ minWidth: 300 }}
                >
                    <MenuItem value="caseName">Case</MenuItem>
                    <MenuItem value="lotID">Lot</MenuItem>
                    <MenuItem value="sku">External Sku</MenuItem>
                    <MenuItem value="fbmPartnerSKU">Partner Sku</MenuItem>
                    <MenuItem value="poID">Purchase Order</MenuItem>
                    <MenuItem value="locationName">Location</MenuItem>
                    <MenuItem value="palletName">Pallet</MenuItem>
                </TextField>
                <TextField
                    label=""
                    name="searchTerm"
                    value={formData.searchTerm}
                    onChange={onInputChange}
                    variant="outlined"
                    sx={{ minWidth: 300 }}
                />
                <Button type="submit" variant="text" color="primary">
                    Search
                </Button>
                {hasFormData && (
                    <Button onClick={handleClear} variant="text" color="secondary">
                        Clear
                    </Button>
                )}
            </form>
        </Box>
    );
};

export { LocationInventorySearchBar };

import React, { useEffect, useState, useContext } from 'react';
import { DataTable, Notifications } from '../../../../shared';
import { LocationAccuracyTimeframeSelection } from './locationAccuracyTimeframeSelection';
import moment from 'moment';
import { LocationAccuracySummary } from './locationAccuracySummary';
import { UserContext } from '../../../../contexts/UserContext';
import { CycleCountApi } from '../../../../api/cycleCount';

const cycleCountApi = new CycleCountApi();

const LocationAccuracyTab = () => {
    const initialStartDate = moment().subtract(1, 'w').format('YYYY-MM-DD');
    const initialEndDate = moment().format('YYYY-MM-DD');
    const user = useContext(UserContext);

    const [dateRange, setDateRange] = useState({
        start: initialStartDate,
        end: initialEndDate,
    });

    const [rows, setRows] = useState([]);
    const [summaryRow, setSummaryRow] = useState({});
    const [notification, setNotification] = useState({ text: '', level: '' });

    useEffect(() => {
        getLocationAccuracy();
    }, [dateRange]);

    const columns = [
        { Header: 'Location', accessor: 'locationName' },
        { Header: 'Status', accessor: 'status' },
        { Header: 'Datetime Started', accessor: 'dateStarted' },
        { Header: 'Datetime Ended', accessor: 'dateEnded' },
        { Header: 'User', accessor: 'authUser' },
        { Header: 'Skus Expected', accessor: 'skusExpected' },
        { Header: 'Skus Accurate', accessor: 'skusCounted' },
        { Header: 'Units Expected', accessor: 'unitsExpected' },
        { Header: 'Units Counted', accessor: 'unitsCounted' },
        { Header: 'Lots Expected', accessor: 'lotsExpected' },
        { Header: 'Lots Accurate', accessor: 'lotsCounted' },
        { Header: 'Pallets Expected', accessor: 'palletsExpected' },
        { Header: 'Pallets Accurate', accessor: 'palletsCounted' },
        { Header: 'Sku Accuracy', accessor: 'skuAccuracy' },
        { Header: 'Unit Accuracy', accessor: 'unitAccuracy' },
    ];

    const getLocationAccuracy = async () => {
        const res = await cycleCountApi.getLocationCycleCountResults(
            user.getFacilityID(),
            dateRange.start,
            dateRange.end
        );
        if (!res.status) {
            setNotification({ text: res.msg, level: 'error' });
            return;
        }

        setRows(res.data.results);
        setSummaryRow(res.data.summary);
    };

    const handleTimeframeSelection = async (formData) => {
        setDateRange({ start: formData.start, end: formData.end });
    };

    return (
        <>
            <Notifications options={notification} />

            <LocationAccuracyTimeframeSelection
                initialStartDate={initialStartDate}
                initialEndDate={initialEndDate}
                onSubmit={handleTimeframeSelection}
            />

            <LocationAccuracySummary summaryRow={summaryRow} />

            <DataTable columns={columns} data={rows} editable={false} tableContainerMaxHeight={800} />
        </>
    );
};

export { LocationAccuracyTab };
